<template>
  <div id="app">
    <router-view />
    <Login v-if="$store.getters.isLoginModel" />
    <AiChart />
  </div>
</template>
<script>
import Login from '@/components/Login'
import AiChart from '@/components/AiChart/index.vue'
export default {
  name: 'App',
  components: { Login, AiChart },
  mounted() {
    console.log('宽度', window.screen.width)
  }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}
</style>
