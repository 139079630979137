let IP, PORT
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  IP = '127.0.0.1'
  PORT = '8099'
} else {
  // 生产环境
  IP = document.location.hostname
  // IP = '192.168.4.166'
  PORT = '8088'
}
// 本地
// const config = {
//   VUE_APP_BASE_API: `http://${IP}:${PORT}/`,
//   VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
//   VUE_APP_RESOURCE_URL: `http://192.168.4.166/resource/`,
//   VUE_APP_WEBGL_URL: `http://192.168.4.166:8033/`,
//   VUE_APP_AI_URL: 'http://192.168.100.175:8013',
//   VUE_APP_WEBGL_LCSW_URL: 'http://192.168.4.11:3000' // 临床思维地址
// }

// 测试环境
// const config = {
//   VUE_APP_BASE_API: `http://192.168.4.166:8099/`,
//   VUE_APP_SOCKET_URL: `ws://192.168.4.166:8099/`,
//   VUE_APP_RESOURCE_URL: `http://192.168.4.166/resourceTest`,
//   VUE_APP_WEBGL_URL: `http://192.168.4.166:8033/`,
//   VUE_APP_AI_URL: 'http://192.168.100.175:8013'
// }

// 开发环境
// const config = {
//   VUE_APP_BASE_API: `http://${IP}:${PORT}/`,
//   VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
//   VUE_APP_RESOURCE_URL: `http://192.168.4.166/resourceDev/`,
//   VUE_APP_WEBGL_URL: `http://192.168.4.166:8033/`,
//   VUE_APP_AI_URL: 'http://192.168.100.175:8013',
//   VUE_APP_WEBGL_LCSW_URL: 'http://192.168.4.11:3000' // 临床思维地址
// }

// Ai环境
// const config = {
//   VUE_APP_BASE_API: `http://${IP}:${PORT}/`,
//   VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
//   VUE_APP_RESOURCE_URL: `http://192.168.100.175//resourceAi/`,
//   VUE_APP_WEBGL_URL: `http://192.168.100.175:8033/`,
//   VUE_APP_AI_URL: 'http://192.168.100.175:8013',
//   VUE_APP_WEBGL_LCSW_URL: 'http://192.168.100.175:3000' // 临床思维地址
// }

// 阿里云 https
const config = {
  VUE_APP_BASE_API: `https://${IP}/api/`,
  VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
  VUE_APP_RESOURCE_URL: `https://${IP}/resourcePrd/`,
  VUE_APP_WEBGL_URL: `https://${IP}/resourcePrd/webgl/`,
  VUE_APP_AI_URL: 'http://capture.tellyescloud.com:8013',
  VUE_APP_WEBGL_LCSW_URL: 'http://192.168.100.175:3000' // 临床思维地址
}
export default config

