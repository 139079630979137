<template>
  <div
    class="ai-chart-container"
    :style="{width: $store.getters.isAiModel? '342px' : '0px'}"
  >
    <div
      v-show="!$store.getters.isAiModel"
      class="menu-container"
      :style="{right:left+'px',bottom:top+'px'}"
      draggable="true"
      @dragstart="dragstart($event)"
      @dragend.stop="dragend($event)"
      @click="menuClick"
    >
      <!--      @mousedown="mouseDown"-->
      <!--      @mousemove="mouseMove"-->
      <!--      @mouseup="mouseUp"-->

      <img
        class="ai-png"
        src="@/assets/home/AI助手1.png"
        alt=""
      >
    </div>
    <div class="chart-container">
      <div class="chart-title">
        <div class="left">
          <img
            src="./img/ai.png"
            alt="AI"
          >
          <div
            style="margin-left: 10px"
            class="title"
          >AI 助手</div>
        </div>
        <a
          class="close"
          @click="$store.commit('CLOSE_AI')"
        ><img
          src="./img/关闭-白.png"
          alt="关闭"
        ></a>
      </div>
      <div
        id="chat-box"
        class="content-list"
      >
        <div
          v-if="emptyFlag"
          class="empty-container"
        >
          你好呀！我是AI全能小助手，有什么问题，尽管问我哦！
        </div>

        <div
          v-for="item in list"
          class="content-item"
        >
          <div
            v-if="item.type === '1'"
            class="question-container"
          >
            <div class="question">{{ item.content }}</div>
          </div>
          <div
            v-if="item.type === '2'"
            class="answer-container"
          >
            <div
              class="answer"
              v-html="item.content"
            />
          </div>
        </div>
        <div
          v-if="flag"
          class="content-item"
        >
          <div
            class="answer-container"
          >
            <div
              class="answer"
              v-html="flagContent"
            />
          </div>
        </div>
      </div>
      <div class="chart-footer">
        <div class="text-area">
          <textarea
            id="textarea"
            v-model="value"
            autocomplete="off"
            placeholder="请输入您的问题获取AI辅助功能"
            maxlength="2000"
            style="resize: none;"
            @keydown.enter.prevent="send"
          />
          <div class="operate-container">
            <div class="operate-left">{{ value.length }}/2000</div>
            <div
              class="operate-right"
              @click="send"
            >
              <img
                v-if="value"
                src="./img/发送.png"
                alt="发送"
              >
              <img
                v-else
                src="./img/发送灰.png"
                alt="发送"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import { marked } from 'marked'
import config from '@/config'

export default {
  name: 'AiChartIndex',
  data() {
    return {
      isOpen: this.$store.getters.isAiModel,
      value: '',
      list: [],
      flag: false,
      flagContent: '',
      flagStr: '',
      emptyFlag: true,
      questionList: [],
      URL: '',
      systemContent: '',
      modelName: '',
      temperature: '',
      blockWordsUrl: '',
      left: 32,
      top: 85,
      aiUrl: config.VUE_APP_AI_URL
    }
  },
  created() {
    window.addEventListener('click', this.handleClick)
    this.queryPath()
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick)
  },
  methods: {
    dragstart(e) {
      this.left = window.innerWidth - e.clientX - 27
      this.top = window.innerHeight - e.clientY - 27
    },
    dragend(e) {
      this.left = window.innerWidth - e.clientX - 27
      this.top = window.innerHeight - e.clientY - 27
    },
    handleClick(event) {
      const dom = document.querySelector('.chart-container')
      const excludes = document.querySelector('.ai-png')
      // 检查点击的元素不在 .header-bar-content 内，并且不是 excludes 元素
      if (!dom.contains(event.target)) {
        if (dom && !dom.contains(event.target) && event.target !== excludes) {
          this.$store.commit('CLOSE_AI')
        }
      }
    },
    queryPath() {
      // Task.getSystemLLMURL().then(res => {
      //   this.URL = res.data.URL
      //   this.systemContent = res.data.systemContent
      //   this.modelName = res.data.modelName
      // })

      fetch(`${this.aiUrl}/api/sensitiveWord/getSystemLLMURL`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'language': 'ZH' })
      }).then(resp => resp.json())
        .then(jsonResponse => {
          console.log('JSON response:', jsonResponse) // 处理JSON响应数据
          const data = jsonResponse.result
          this.URL = data.URL
          this.systemContent = data.systemContent
          this.modelName = data.modelName
          this.temperature = data.temperature
          this.blockWordsUrl = data.blockWordsUrl
        })
    },
    menuClick() {
      this.$store.commit('OPEN_AI')
    },
    async send() {
      // 进行判断内容是否合法
      const respText = await fetch(this.blockWordsUrl + '?text=' + this.value, {
        method: 'GET'
      })
      const respTextJson = await respText.json()
      if (respTextJson.result) {
        const item1 = {
          type: '1',
          content: this.value
        }
        this.list.push(item1)
        const item2 = {
          type: '2',
          content: '<p>您输入的内容包含敏感词，请重新输入</p>'
        }
        this.emptyFlag = false
        this.flag = false
        this.list.push(item2)
        return
      }
      this.emptyFlag = false
      const messagesList = [{
        'role': 'system',
        'content': this.systemContent
      }]
      this.questionList.forEach((item) => {
        messagesList.push({ 'role': 'assistant', 'content': item })
      })
      messagesList.push({ 'role': 'user', 'content': this.value })
      const item = {
        type: '1',
        content: this.value
      }
      this.questionList.push(this.value)
      this.list.push(item)
      const params = {
        'model': this.modelName,
        'stream': true,
        'messages': messagesList
      }
      const resp = await fetch(this.URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })
      this.value = ''
      this.flagContent = ''
      this.flagStr = ''
      this.flag = true
      const reader = resp.body.getReader()
      const decoder = new TextDecoder('utf-8') // 解码器
      // 循环拿到所有数据 done读取完毕
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        const str = decoder.decode(value)
        const _data = str?.split('\n\n') || []

        for (const it of _data) {
          if (it === 'data: [DONE]') {
            const item = {
              type: '2',
              content: this.flagContent
            }
            this.flag = false
            this.list.push(item)
            break
          }
          const newStr = it.replace(/^(data: \[DONE\])|^(data: )/, '')
          try {
            // eslint-disable-next-line no-unused-vars
            this.flagStr += JSON.parse(newStr).choices[0].delta.content ? JSON.parse(newStr).choices[0].delta.content : ''
            this.flagContent = marked.parse(this.flagStr)
            const chatBox = document.getElementById('chat-box')
            chatBox.scrollTop = chatBox.scrollHeight
          } catch (e) {
            console.log(newStr)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
//黑色
.ai-chart-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 342px;
  height: 100%;
  background: #353741;
  z-index: 1000;
  transition: all .2s ease-in-out;
  box-shadow: 0 2px 34px 0 rgba(0,0,0,0.50);
  .menu-container {
    cursor: pointer;
    position: fixed;
    //bottom: 85px;
    //right: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img{
      width: 54px;
    }
  }

  .chart-container {
    height: 100%;
    width: 342px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    padding: 0 10px 0 20px;
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    .chart-title{
      padding: 10px 0;
      color: #ffffff;
      font-family: PingFang SC;
      font-size: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        bottom: 0px;
        left: -20px;
        height: 1px;
        width: calc(100%  + 40px);
        background: #4C4F5C;
      }
      .left{
        display: flex;
        align-items: center;
        img{
          height: 38px;
          margin-top: 3px;
        }
      }
      a{
        img{
          height: 20px;
          width: 20px;
        }
      }
    }
    .content-list{
      height: calc(100% - 215px);
      overflow-y: auto;
      padding-right: 10px;
      margin-top: 15px;
    }
    .empty-container{
      padding: 10px 12px;
      border-radius: 8px;
      font-style: normal;
      line-height: 22px;
      margin-top: 0;
      background: #101221;
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(255,255,255,0.65);
      letter-spacing: 0.68px;
      font-weight: 400;
    }
    .question-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;

      .question {
        padding: 12px 16px;
        line-height: 1.54;
        min-height: 21px;
        background: #37448D;
        border-radius: 8px;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.68px;
        font-weight: 400;
      }
    }

    .answer-container {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;

      .answer {
        border: 2px solid !important;
        color: #222;
        background: #101221;
        padding: 0 16px;
        border-radius: 8px;
        line-height: 1.54;
        min-height: 21px;
        word-wrap: break-word;
        word-break: break-all;
        border-image: linear-gradient(45deg, #00DFFF, #8D4ADF, #F69F65) 1 !important;
        clip-path: inset(0px round 5px);
        animation: rainbow 3s infinite linear;
        filter: hue-rotate(360deg);
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: rgba(255,255,255,0.85);
        letter-spacing: 0.68px;
        font-weight: 400;
      }
    }
    .chart-footer{
      padding: 20px 0;
      margin-right: 10px;
      .text-area{
        background: #101221;
        border: 1px solid #999;
        border-radius: 8px;
        &:focus-within{
          border: 1px solid #3572f0;
        }
      }
      textarea{
        display: block;
        resize: vertical;
        padding: 5px 15px;
        line-height: 1.5;
        box-sizing: border-box;
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        background-color: #101221;
        background-image: none;
        border: none;
        border-radius: 8px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        &:focus{
          outline: none;
        }
      }
      .operate-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 0  15px;
        color: white;
        .operate-right{
          cursor: pointer;
          img{
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}

//白色
//.ai-chart-container {
//  position: fixed;
//  top: 0;
//  right: 0;
//  width: 342px;
//  height: 100vh;
//  background: #fff;
//  z-index: 20;
//  transition: all .2s ease-in-out;
//  box-shadow: -15px 0 10px -12px #eee;
//  .menu-container {
//    cursor: pointer;
//    height: 40px;
//    width: 40px;
//    border-radius: 50% 0 0 50%;
//    position: absolute;
//    background: linear-gradient(180deg,#bed0ff,#98aeff);
//    bottom: 20%;
//    left: -40px;
//    display: flex;
//    flex-direction: row;
//    align-items: center;
//    justify-content: center;
//    .text{
//      height: 36px;
//      width: 36px;
//      border-radius: 5px;
//      color: white;
//      text-align: center;
//      line-height: 22px;
//      margin-left: 5px;
//      margin-top: 2px;
//    }
//  }
//
//  .chart-container {
//    height: 100%;
//    width: 342px;
//    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
//    padding: 0 10px 0 20px;
//    .chart-title{
//      padding: 20px 0;
//      color: #1c1f1e;
//      font-family: PingFang SC;
//      font-size: 22px;
//      font-weight: 600;
//      display: flex;
//      align-items: center;
//      justify-content: space-between;
//      margin-right: 10px;
//      .left{
//        display: flex;
//        align-items: center;
//        img{
//          height: 38px;
//          margin-top: 3px;
//        }
//      }
//      a{
//        img{
//          height: 20px;
//          width: 20px;
//        }
//      }
//    }
//    .content-list{
//      height: calc(100% - 230px);
//      overflow-y: auto;
//      padding-right: 10px;
//    }
//    .empty-container{
//      padding: 10px 12px;
//      border-radius: 8px;
//      border: 1px solid #eaedf1;
//      background: #f6f8fa;
//      font-size: 16px;
//      font-style: normal;
//      font-weight: 400;
//      line-height: 22px;
//      letter-spacing: .039px;
//      margin-top: 18px;
//    }
//    .question-container {
//      display: flex;
//      justify-content: flex-end;
//      margin-bottom: 15px;
//
//      .question {
//        color: #fff;
//        background: #3572f0;
//        border-color: #3572f0;
//        padding: 12px 16px;
//        border-radius: 8px;
//        line-height: 1.54;
//        min-height: 21px;
//      }
//    }
//
//    .answer-container {
//      margin-bottom: 15px;
//      display: flex;
//      justify-content: flex-start;
//
//      .answer {
//        color: #222;
//        background: #edf2f6;
//        border-color: #edf2f6;
//        padding: 0 16px;
//        border-radius: 8px;
//        line-height: 1.54;
//        min-height: 21px;
//        word-wrap: break-word;
//        word-break: break-all;
//        //white-space: pre-wrap;
//        //::v-deep p{
//        //  margin: 0!important;
//        //}
//        //::v-deep ol{
//        //  margin: 0!important;
//        //}
//      }
//    }
//    .chart-footer{
//      padding: 20px 0;
//      margin-right: 10px;
//      .text-area{
//        border: 2px solid #999;
//        border-radius: 8px;
//        &:focus-within{
//          border: 2px solid #3572f0;
//        }
//      }
//      textarea{
//        display: block;
//        resize: vertical;
//        padding: 5px 15px;
//        line-height: 1.5;
//        box-sizing: border-box;
//        width: 100%;
//        font-size: 16px;
//        color: #333;
//        background-color: #fff;
//        background-image: none;
//        border: none;
//        border-radius: 8px;
//        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
//        &:focus{
//          outline: none;
//        }
//      }
//      .operate-container{
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        margin-top: 10px;
//        padding: 0  15px;
//        .operate-right{
//          cursor: pointer;
//          img{
//            height: 30px;
//            width: 30px;
//          }
//        }
//      }
//    }
//  }
//}
</style>
