import Vue from 'vue'
import dayjs from 'dayjs'
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
const globalFilter = {

  // 分钟转小时 小数点保留一位
  minutesToHours(minutes) {
    if (minutes === undefined || isNaN(minutes)) {
      return 0
    }
    const hours = Math.floor(minutes / 60) // 获取小时部分
    const remainingMinutes = minutes % 60 // 获取剩余的分钟部分
    const num = hours + remainingMinutes / 60
    const value = (Math.round(num * 10) / 10)
    return value === Math.floor(value) ? Math.floor(value) : value.toFixed(1)
  },
  // 分钟转小时 整数
  minutesToHoursInt(minutes) {
    if (minutes === undefined || isNaN(minutes)) {
      return 0
    }
    const hours = Math.floor(minutes / 60) // 获取小时部分
    const remainingMinutes = minutes % 60 // 获取剩余的分钟部分
    const num = hours + remainingMinutes / 60
    const value = (Math.round(num * 1000) / 1000)
    return parseInt(value)
  },
  intMinValue: function(value) {
    if (!value) {
      return 0
    }
    return Math.ceil(value)
  },

  /**
   * 保留整数
   * @param value
   * @returns {*|number|string}
   */
  integerValue: function(value) {
    if (value === undefined || isNaN(parseFloat(value))) {
      return ''
    } else if (typeof value === 'number') {
      return value.toFixed(0)
    } else if (typeof value === 'string') {
      return parseInt(value, 10)
    } else {
      return value
    }
  },
  /**
   * 保留一位小数
   * @param value
   * @param precision
   * @returns {string}
   */
  floatValueOneDecimal: function(value) {
    if (value === undefined || isNaN(parseFloat(value))) {
      return ''
    } else {
      const rounded = Math.round(value * 10) / 10
      return rounded === Math.floor(rounded) ? Math.floor(rounded) : rounded.toFixed(1)
    }
  },

  /**
   * 数值转时分秒
   * 时间转换 0 =》00:00:00
   */
  numToTime: function(number) {
    return dayjs.duration(number, 'seconds').format('HH:mm:ss')
  }
}
Object.keys(globalFilter).forEach(k => Vue.filter(k, globalFilter[k]))

export default globalFilter
